import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// import EmailIcon from "@material-ui/icons/Email";

// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import Button from "components/CustomButtons/Button.jsx";
import WorkSection from "./Sections/WorkSection.jsx"
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import SEO from "../../components/SEO.jsx";

class ContactUs extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO/>
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/squad.jpeg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div
            className={classes.center}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h2>Contact Us</h2>
          </div>
          <br />
          <GridContainer
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <GridItem sm={6}>
              <h3>
                There's a range of ways to get in touch, you can contact us on
                social media:
              </h3>
              <List className={classes.list} style={{ color: "#123E78", flexDirection: "row", display: "flex" }}>
                <ListItem className={classes.listItem}>
                  <Tooltip
                    id="instagram-twitter"
                    title="Follow us on twitter"
                    placement={
                      typeof window !== "undefined" && window.innerWidth > 959
                        ? "top"
                        : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      href="https://twitter.com/westbeachscot"
                      target="_blank"
                      color="transparent"
                      className={classes.navLink}
                    >
                      <FaTwitter />
                    </Button>
                  </Tooltip>
                </ListItem>
                <ListItem className={classes}>
                  <Tooltip
                    id="instagram-facebook"
                    title="Follow us on facebook"
                    placement={
                      typeof window !== "undefined" && window.innerWidth > 959
                        ? "top"
                        : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="transparent"
                      href="https://www.facebook.com/westbeachscot"
                      target="_blank"
                      className={classes.navLink}
                    >
                      <FaFacebook />
                    </Button>
                  </Tooltip>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Tooltip
                    id="instagram-tooltip"
                    title="Follow us on instagram"
                    placement={
                      typeof window !== "undefined" && window.innerWidth > 959
                        ? "top"
                        : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="transparent"
                      href="https://instagram.com/WestcoastbeachVC"
                      target="_blank"
                      className={classes.navLink}
                    >
                      <FaInstagram />
                    </Button>
                  </Tooltip>
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
          <WorkSection/>
          <br />
          <br />
        </div>

        <Footer />
      </div>
    )
  }
}

export default withStyles(componentsStyle)(ContactUs)

import React, { Component } from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
// import Components from "./Components/Components.jsx";
import LoginPage from "./LoginPage/LoginPage.jsx";
import Sponsorship from "./sponsorship.js";
import About from "./about.js"
import GalleryPage from "./competitions.js"
import ContactUs from './contact.js'
import Membership from './membership.js'
import Welcome from './welcome.js'
import Success from './success.js'
import News from './news.js'


let hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/sponsorship" component={Sponsorship} />
      <Route path="/contact" component={LoginPage} />
      <Route path="/membership" component={Membership} />
      <Route path="/competitions" component={GalleryPage} />
      <Route path="/contact" component={ContactUs} />
      <Route path="/success" component={Success} />
      <Route path="/news" component={News} />
      <Route path="/" component={Welcome} />
    </Switch>
  </Router>
);
